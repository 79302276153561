<template>
    <div class="c-app flex-row align-items-center" :style="{backgroundImage: 'url('+organization_background+')', backgroundSize: 'cover','box-shadow': 'inset 0 0 0 1000px rgba(108, 122, 137, 0.35)' }" >
        <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
        <CContainer >
            <CRow class="justify-content-center">
                
                <CCol md="4">
                    <div class="invalid-organization" v-if="!organization_exists">
                        Invalid Organization. <br>Please ask your system Administrator.
                    </div>
                    <br>
                    <CCard class="p-4 login-panel" style="border-radius:20px;box-shadow: 0px 5px 80px 0px;" v-if="!default_password">
                        <CForm @submit.prevent="login">
                            <center>
                                <img :src="organization_logo" class="company-logo">
                            </center>
                            <br>
                            <h1>Login</h1>
                            <p class="text-muted">Sign In to your account</p>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.credentials.email.$model"
                                :isValid="checkIfValid('email')"
                                :disabled="disabled_input"
                                placeholder="Email" 
                                autocomplete="off" 
                                v-model="credentials.email" 
                                type="email"
                            >
                                <template #prepend-content><CIcon name="cil-user" /></template>
                            </CInput>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.credentials.password.$model"
                                :isValid="checkIfValid('password')"
                                :disabled="disabled_input"
                                autocomplete="off" 
                                v-model="credentials.password" 
                                type="password"
                                placeholder="Password" 
                            >
                                <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                            </CInput>
                            <div class="form-group" v-if="organization_exists">
                            <p-check 
                                class="p-round p-jelly" 
                                color="danger"
                                :disabled="disabled_input"
                                v-model="credentials.remember_me">
                                Remember me
                            </p-check>
                            <router-link 
                                :to="{path: `/${organization_name}/account-recovery`}"
                                :event="!disabled_input ? 'click' : ''"
                                class="float-right text-primary"
                            >
                                <i>
                                    Forgot Password?
                                </i>

                            </router-link>
                            </div>
                            <CRow v-if="!organization_exists">
                                <CCol col="12" class="text-left" >
                                    <router-link :to="{path: `/`}">
                                        <CButton
                                            color="secondary" 
                                        > 
                                            <font-awesome-icon icon="arrow-left"/> Back
                                        </CButton>
                                    </router-link>
                                </CCol>
                            </CRow>
                            <CRow v-if="organization_exists">
                                 <CCol col="6" class="text-leftt" >
                                    <router-link :to="{path: `/`}">
                                        <CButton
                                            :disabled="disabled_input"
                                            color="secondary" 
                                        > 
                                            <font-awesome-icon icon="arrow-left"/> Back
                                        </CButton>
                                    </router-link>
                                </CCol>
                                <CCol col="6" class="text-right" >
                                    <CButton 
                                        block
                                        :disabled="!isValid || !submit_btn" 
                                        type="submit" 
                                        color="primary" 
                                    >
                                        <font-awesome-icon 
                                            :icon="submit_btn ? 'sign-in-alt' : 'circle-notch'"
                                            :pulse="!submit_btn"
                                        /> Sign in
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCard>
                    <CCard class="p-4 login-panel" v-if="default_password">
                        <CForm @submit.prevent="changeDefaultPassword">
                            <center>
                                <img :src="organization_logo" class="company-logo">
                            </center>
                            <br>
                            <h4>Default Password Detected.</h4>
                            <p class="text-muted">Please update user password.</p>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.dp.old_password.$model"
                                :isValid="checkIfValidPassword('old_password')"
                                placeholder="Temporary Password" 
                                autocomplete="off" 
                                type="password"
                                v-model="dp.old_password"
                            >
                                <template #prepend-content>
                                    <font-awesome-icon icon="lock"/>
                                </template>
                            </CInput>
                            <hr>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.dp.new_password.$model"
                                :isValid="checkIfValidPassword('new_password')"
                                placeholder="New Password" 
                                autocomplete="off" 
                                :type="password_text" 
                                v-model="dp.new_password"
                                invalidFeedback="Required password containing at least: number, uppercase and lowercase letter, 8 characters"
                            >
                                <template #prepend-content>
                                    <font-awesome-icon icon="lock"/>
                                </template>
                                <template #append>
                                    <CButton
                                        color="primary"
                                        @click="password_view = password_view === false ? true : false, showPassword()"
                                    >
                                        <font-awesome-icon :icon="!password_view ? 'eye-slash' : 'eye'"/>
                                    </CButton>
                                </template>
                            </CInput>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.dp.new_confirm_password.$model"
                                :isValid="checkIfValidPassword('new_confirm_password')"
                                placeholder="Confirm New Password" 
                                autocomplete="off" 
                                :type="password_text" 
                                v-model="dp.new_confirm_password"
                                invalidFeedback="New and confirm password must match"
                            >
                                <template #prepend-content>
                                    <font-awesome-icon icon="lock"/>
                                </template>
                            </CInput>
                            <CRow>
                                <CCol col="7" class="text-left" >
                                    <CButton 
                                        :disabled="!isValidPassword"
                                        type="submit" 
                                        color="primary" 
                                    >
                                        <font-awesome-icon icon="file-export"/> Update password
                                    </CButton>
                                </CCol>
                                <CCol col="5">
                                    <CButton 
                                        class="float-right"
                                        type="submit" 
                                        color="danger" 
                                        @click="default_password = !default_password"
                                    >
                                        <font-awesome-icon icon="window-close"/> Cancel
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<script>
import { required, email, sameAs, minLength, helpers } from "vuelidate/lib/validators"
export default {
    name: "Login",
    data() {
        return {
            disabled_input: true,
            param: {
                token: "",
                experimental_1: "",
                experimental_2: "",
            },
            base_url: window.location.origin,
            default_password: false,
            password_view: false,
            password_text: 'password',
            submit_btn: true,
            organization_exists: true,
            credentials: {
                email: "",
                password: "",
                remember_me: false,
            },
            organization_name: this.$route.params.orgName,
            organization_key: null,
            organization_logo: null,
            organization_background: null,
            _cn: null,
            dp: {
                user_id: null,
                old_password: null,
                new_password: null,
                new_confirm_password: null,
            }
        };
    },
    // mounted(){
    //     this.$cookies.set("cookieName","cookieValue");
    // },
    computed: {
        isValid () { return !this.$v.credentials.$invalid },
        isDirty () { return tedentials.$anyDirty },

        
        isValidPassword () { return !this.$v.dp.$invalid },
        isDirtyPassword () { return this.$v.dp.$anyDirty },
    },
    validations: { // FORM Validation (Vue)
        credentials: {
            email: { required, email, },
            password: { required, },
        },
        dp: {
            old_password: {required},
            new_password: {required, minLength: minLength(8), strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/) },
            new_confirm_password: {required, sameAsPassword: sameAs('new_password') },
        },
    },
    created() {
        this.getOrganization()
        .then(() => this.checkCookies())
        .then(() => this.validate());
        document.title = "Login"
    },
    methods: {
        async getOrganization() {
        this.$Progress.start();
            try {
                const response = await axios.get(process.env.VUE_APP_BACKEND_URL+'/organization/'+this.organization_name, {validateStatus: () => true});
                if(response.status === 200) {
                    this.organization_key = response.data.data.org_key;
                    this.$store.commit('SET_ORGANIZATION_LOGO', this.$backend_url+"/organization/images/logo/"+response.data.data.org_key);
                    this.organization_logo = this.$store.getters.getOrganizationLogo;
                    this.organization_background = this.$backend_url+"/organization/images/background_image/"+response.data.data.org_key;
                    this.$store.commit("SET_ORGANIZATION_NAME", response.data.data.org_name);
                    this.$store.commit("SET_ORGANIZATION_KEY", response.data.data.org_key);
                } else {
                    this.organization_exists = false;
                }
            } catch (error) {
                console.error("Error while fetching organization:", error);
            } 
        },
        async checkCookies() {
            window.axios.defaults.headers.common['Organization-Name'] = this.$store.getters.getOrganizationName;
            window.axios.defaults.headers.common['Organization-Key'] = this.$store.getters.getOrganizationKey;
            this.param.token = this.$cookies.get('remember_user_token');
            this.param.experimental_1 = this.$cookies.get('experimental_1');
            this.param.experimental_2 = this.$cookies.get('experimental_2');
            if(this.param.token && this.param.token != "") {
                try {
                    const response = await axios.post(process.env.VUE_APP_BACKEND_URL+"/remember_user_token", this.param, {validateStatus: () => true});
                    if (response.status === 200 && response.data.data.response !== false) {
                        this.credentials.email = response.data.data.email;
                        this.credentials.password = response.data.data.password;
                        this.credentials.remember_me = response.data.data.password;
                        return;
                    }
                } catch (error) {
                    console.error("Error while checking cookies:", error);
                } finally {
                    this.disabled_input = false;
                    this.$Progress.finish();
                }
            } 

            this.disabled_input = false;
            this.$Progress.finish();
            return  
        },
        validate() {
            this.$v.credentials.$touch();
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.credentials[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validatePassword: function () {
            this.$v.dp.$touch()
        },
        checkIfValidPassword: function (fieldName) {
            const field = this.$v.dp[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateForm: function () {
            if (!this.credentials.email || !this.credentials.password) {
                return false;
            }
            return true;
        },
        validatePasswordInput: function() {
            if(
                !this.dp.old_password ||
                !this.dp.new_password ||
                !this.dp.new_confirm_password
                ) return false;
            return true;
        },
        showPassword: function() {
            if(this.password_view) {
                this.password_text = 'text'
            } else {
                this.password_text = 'password'
            }
        },
        login: function () {
            window.axios.defaults.headers.common['Organization-Name'] = this.$store.getters.getOrganizationName
            window.axios.defaults.headers.common['Organization-Key'] = this.$store.getters.getOrganizationKey
            this.submit_btn = false
            this.disabled_input = true;
            if (this.validateForm()) {
                axios.post(process.env.VUE_APP_BACKEND_URL+"/login", this.credentials, {validateStatus: () => true})
                .then((response) => {
                    if (response.status == 200) {
                        this.$swal({
                            toast: true,
                            position: "center",
                            showConfirmButton: false,
                            timer: 3000,
                            icon: "success",
                            title: "Login Successfully!",
                            timerProgressBar: true,
                        }).then( () => {
                            var _dt = new Date();
                            _dt.setDate(_dt.getDate() - 1);
                            if(response.data.data.cookie == false) {
                                this.$cookies.set("remember_user_token",'',_dt.toUTCString(),"/");
                                this.$cookies.set("experimental_1",'',_dt.toUTCString(),"/");
                                this.$cookies.set("experimental_2",'',_dt.toUTCString(),"/");
                            } else {
                                this.$cookies.set("remember_user_token",response.data.data.cookie.cookie_token,response.data.data.cookie.cookie_max_age,"/");
                                this.$cookies.set("experimental_1",response.data.data.cookie.cookie_name,response.data.data.cookie.cookie_max_age,"/");
                                this.$cookies.set("experimental_2",response.data.data.cookie.cookie_oldtoken,response.data.data.cookie.cookie_max_age,"/");
                            }
                            
                            this.$store.commit("LOGGED_USER", response.data.data);
                            this.$store.commit("SET_USER_DETAILS", response.data.data);                            
                            this.$store.commit("SET_ORGANIZATION_NAME", this.organization_name);
                            this.$store.commit("SET_ORGANIZATION_KEY", this.organization_key);
                            
                            //adding user_preference
                            let user_preference = response.data.data.user_preferences;
                            if(user_preference != null) {
                                let isDark = user_preference.dark_mode == 0? false : true;
                                let documentRouting = user_preference.document_routing;
                                let formApplication = user_preference.form_application;
                                document.body.classList.remove('theme-dark', 'theme-light');
                                if (isDark == true) {
                                    if(!this.$store.getters.getDarkMode) {
                                        this.$store.commit('toggle', 'dm');
                                    }
                                }
                                this.$store.commit("SET_USER_PREFERENCE", user_preference);
                                this.$store.commit('documentRoutingViews', documentRouting);
                                this.$store.commit('formApplicationViews', formApplication);
                            } else {
                                this.$store.commit('documentRoutingViews', 'table');
                                this.$store.commit('formApplicationViews', 'table');
                            }
                            
                            this.$router.push({ path: `/${this.organization_name}/dashboard` });
                            window.axios.defaults.baseUrl = process.env.VUE_APP_BACKEND_URL + '/api/v1'
                            window.axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.getUserToken;

                            this.getUserNavigation();
                            this.getAppNavigation();
                        });
                        return;
                    } else if (response.status == 300) {
                        if(response.data.message == 'Default Password') {
                            this.dp.user_id = response.data.data.user_id;
                            this._cn = response.data.data._cn;
                            this.$swal({
                                customClass: 'ta-left',
                                toast: true,
                                position: 'center',
                                icon: "error",
                                title: "Default password detected.",
                                html: `This will redirect you to update password form.`,
                                timer: 5000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            }).then(() => {
                                this.default_password = true;
                            })
                        }
                    }   
                    this.submit_btn = true
                    this.disabled_input = false;
                })
            } else {
                this.$swal({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    timerProgressBar: true,
                })
            }
        },
        changeDefaultPassword: function () {
            let data = {
                user_id: this.dp.user_id,
                old_password: this.dp.old_password,
                new_password: this.dp.new_password,
                new_confirm_password: this.dp.new_confirm_password,
                _cn: this._cn,
            };
            if (this.validatePasswordInput()) {
                axios.post(process.env.VUE_APP_BACKEND_URL+"/change-default-password", data, {validateStatus: () => true})
                .then((response) => {
                    if (response.status == 200) {
                        this.$swal({
                            toast: true,
                            position: 'center',
                            icon: "success",
                            title: "Password updated.",
                            timer: 3000,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        }).then(() => {
                            this.default_password = false;
                        })
                    }
                })
            } else {
                this.$swal({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    timerProgressBar: true,
                })
            }
        }
    },
    watch: {
        default_password: function (value) {
            if(value) {
                this.validatePassword();
            }
        }
    }
}
</script>
